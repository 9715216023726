<template>
  <div v-if="item && item.id" class="zt-page-content">
    <div class="block block-1">
      <div v-if="item.factory">
        <span>买方公司</span>
        {{ item.comp.name }}
      </div>
      <div>
        <span>订单状态</span>
        <span class="font14-grey" v-if="item.state < 5">未付款</span>
        <span class="font14-grey" v-if="item.state == 5">已付款</span>
        <span class="font14-grey" v-if="item.state >= 6">已确认收款</span>
        <!-- <span v-if="item.tradeOrder && item.tradeOrder.state==6">订单完成</span> -->
      </div>
    </div>
    <div class="block block-2">
      <h3>商品信息</h3>
      <div class="order-list">
        <div v-for="(item1, i) in item.entries" :key="i" class="order-block">
          <div class="order-top">
            <img
              @click="gotoDetail(item1.item)"
              v-if="
                item1.item &&
                item1.item.photoRender &&
                item1.item.photoRender.length > 0
              "
              :src="imgUrl + item1.item.photoRender[0]"
            />
            <div class="img-right">
              <div class="item-name flex flex-align-center" v-if="item1.item">
                <span class="name" @click="gotoDetail(item1.item)">{{
                  item1.item.name
                }}</span>
                <span
                  class="series"
                  v-if="item1.item.series && item1.item.series.name"
                  >{{ item1.item.series.name }}</span
                >
              </div>
              <div class="feature" v-if="item1.item">
                <span v-if="item1.item.cat">{{ item1.item.cat.name }}</span>
                <span v-if="item1.item.style">{{ item1.item.style.name }}</span>
              </div>
              <div class="feature" v-if="item1.itemSku">
                <span v-if="item1.itemSku.material">{{
                  item1.itemSku.material.name
                }}</span>
                <span v-if="item1.itemSku.color">{{
                  item1.itemSku.color.name
                }}</span>
                <span
                  v-for="(val, n) in item1.itemSku.catAttrValues"
                  :key="n"
                  >{{ val.value.value }}</span
                >
              </div>
              <div v-if="item1.itemSku" class="code">
                条形编码：{{ item1.itemSku.code }}
              </div>
              <div class="amount">
                <span>数量</span>
                {{ item1.count }}
              </div>
              <div class="price">
                <span>总价</span>
                <b>￥{{ item1.amount | priceFilt }}</b>
              </div>
              <div v-if="item1.wlState" class="logistic-status">
                <span>物流状态</span>
                {{ item1.wlState }}
              </div>
            </div>
          </div>
          <aps :type="1" :item1="item1"></aps>
        </div>
      </div>
    </div>
    <div class="block block-3">
      <h3>订单信息</h3>
      <div>
        <div class="info-wrap">
          <div>
            <span>交易日期</span>
            {{ item.createdAt | moment("YYYY/MM/DD HH:mm:ss") }}
          </div>
          <div>
            <span>订单编号</span>
            {{ item.code }}
          </div>
        </div>
        <div class="info-wrap">
          <div>
            <span>支付方式</span>
            <span>线下支付</span>
          </div>
          <div>
            <span>送货方式</span>
            --
          </div>
        </div>
        <div v-if="item.address" class="info-wrap">
          <div>
            <span>送货地址</span>
            {{ item.address.province }}{{ item.address.city
            }}{{ item.address.address }}
          </div>
          <div>
            <span>联系方式</span>
            {{ item.address.name }} {{ item.address.phone }}
          </div>
        </div>
        <div class="info-wrap">
          <div>
            <span>订单总金额</span>
            <b>￥{{ item.amount | priceFilt }}</b>
            <!-- （含运费：￥88.00） -->
          </div>
        </div>
      </div>
    </div>
    <div class="block block-3">
      <h3>物流信息</h3>
      <div>
        <div class="info-wrap">
          <div>
            <span>发货方式</span>
            <span v-if="waybill.transportCategory==1">物流</span>
            <span v-else>城配</span>
          </div>
          <!-- <div>
            <span>仓库</span>
            <span>{{waybill.waybill_num}}</span>
          </div> -->
        </div>
        <div class="info-wrap">
          <div>
            <span>运单编号</span>
            <span>{{waybill.waybill_num}}</span>
          </div>
          <!-- <div>
            <span>仓库</span>
            <span>{{waybill.waybill_num}}</span>
          </div> -->
        </div>
        <div class="info-wrap"> 
          <el-button @click="getLogisticsDetail" type="primary" size="small">物流追踪详情</el-button>
        </div>
      </div>
    </div>

    <el-dialog width="800px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
        <div class="trackWaybill">
          <div class="top flex">
            <span>当前状态：</span>
            <span v-if="trackData.length === 0">待收件</span>
            <div v-for="(item, idx) in trackData" :key="idx">
              <div v-if="idx === 0">
                <span v-if="item.siteTrailState==1">已收货</span>
                <span v-if="item.siteTrailState==2">运输中</span>
                <span v-if="item.siteTrailState==3">已签收</span>
                <span v-if="item.siteTrailState==4">派送中</span>
              </div>
            </div>
          </div>
          <el-divider style="margin: 12px 0 24px"></el-divider>
          <div class="box flex">
            <a-timeline>
              <template v-for="(item, index) in trackData">
                <a-timeline-item
                  v-if="index === 0"
                  color="green"
                  :key="index"
                  style="position: relative;left: 162px"
                >
                  <div class="time">{{ item.createTime | moment("YYYY-MM-DD HH:mm:ss") }}</div>
                  <div style="padding-left: 16px">
                    <div class="site-status">
                      <span v-if="item.siteTrailState==1">已收货</span>
                      <span v-if="item.siteTrailState==2">运输中</span>
                      <span v-if="item.siteTrailState==3">已签收</span>
                      <span v-if="item.siteTrailState==4">派送中</span>
                    </div>
                    <div>
                      <span v-if="item.siteTrailState != 3"
                        >【{{ item.siteName }}】</span
                      >
                      <span v-if="item.siteTrailState == 1"
                        >快递员（{{ item.responser }}）收件，电话号码：（{{
                          item.responserTel
                        }}）</span
                      >
                      <span v-if="item.siteTrailState == 2"
                        >快件离开，发往【{{ item.siteName }}】</span
                      >
                      <!-- <span v-if="item.siteTrailState == 2"
                        >快件离开【{{ getLastSite(item) }}】，发往【{{
                          item.siteName
                        }}】</span
                      > -->
                      <span v-if="item.siteTrailState == 3"
                        >您的快递已签收。</span
                      >
                      <span v-if="item.siteTrailState == 4"
                        >快件已到达【{{ item.siteName }}】，快递员（{{
                          item.responser
                        }}）正在派送请保持手机畅通，并耐心等待。</span
                      >
                    </div>
                    <div v-if="item.siteTrailState != 3">
                      <span style="margin-right: 10px"
                        >站点负责人：{{ item.responser }}</span
                      >
                      <span>联系电话：{{ item.responserTel }}</span>
                    </div>
                  </div>
                </a-timeline-item>
                <a-timeline-item
                  v-else
                  color="gray"
                  :key="index"
                  style="position: relative;left: 162px"
                >
                  <p class="time">{{ item.createTime | moment("YYYY-MM-DD HH:mm:ss") }}</p>
                  <div style="padding-left: 16px">
                    <div class="site-status">
                      <span v-if="item.siteTrailState==1">已收货</span>
                      <span v-if="item.siteTrailState==2">运输中</span>
                      <span v-if="item.siteTrailState==3">已签收</span>
                      <span v-if="item.siteTrailState==4">派送中</span>
                    </div>
                    <div>
                      <span v-if="item.siteTrailState != 3"
                        >【{{ item.siteName }}】</span
                      >
                      <span v-if="item.siteTrailState == 1"
                        >快递员（{{ item.responser }}）收件，电话号码：（{{
                          item.responserTel
                        }}）</span
                      >
                      <!-- <span v-if="item.siteTrailState == 2"
                        >快件离开【{{ getLastSite(item) }}】，发往【{{
                          item.siteName
                        }}】</span
                      > -->
                      <span v-if="item.siteTrailState == 2"
                        >快件离开，发往【{{ item.siteName }}】</span
                      >
                      <span v-if="item.siteTrailState == 3"
                        >您的快递已签收。</span
                      >
                      <span v-if="item.siteTrailState == 4"
                        >快件已到达【{{ item.siteName }}】，快递员（{{
                          item.responser
                        }}）正在派送请保持手机畅通，并耐心等待。</span
                      >
                    </div>
                    <div v-if="item.siteTrailState != 3">
                      <span style="margin-right: 10px"
                        >站点负责人：{{ item.responser }}</span
                      >
                      <span>联系电话：{{ item.responserTel }}</span>
                    </div>
                  </div>
                </a-timeline-item>
              </template>
            </a-timeline>
          </div>
          <div class="flex">
            <div class="flex-1"></div>
            <el-button @click="popVisible=false" size="small">关闭</el-button>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {} from "@/service/company";
import { IMG_URL_PRE } from "@/config";
import { getOrderById, getAPSByOrderId, getWaybill, trackWaybill } from "@/service/sale";

export default {
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "物流追踪详情",
      item: null,
      waybill: null,
      trackData: [],
    };
  },
  async created() {
    this.apsInfo = await this.getApsDetail(this.$route.params.id);
    this.refreshItems();
    this.getWaybill();
  },
  methods: {
    getLogisticsDetail() {
      this.popVisible = true;
      trackWaybill({waybill_num: this.waybill.waybill_num}).then(rst => {
        console.log("rst",rst);
        if (rst) {
          this.trackData = rst;
        }
      }).catch(err => {
        console.log(err)
      });
    },
    getWaybill() {
      getWaybill({order_id: this.$route.params.id}).then(rst => {
        console.log("rst",rst);
        if (rst) {
          this.waybill = rst;
        }
      }).catch(err => {
        console.log(err)
      });
    },
    getApsDetail(id) {
      return new Promise((resolve, reject) => {
        getAPSByOrderId(id).then((rst) => {
          console.log("rstgetApsDetail", rst);
          resolve([
            {
              // itemId: "5523903619df4a46a2410674774e7568",
              itemId: rst.itemId,
              list: rst.lists,
            },
          ]);
        });
      });
    },
    gotoDetail(item) {
      console.log(item);
      if (item.priceType == 2) {
        this.$router.push("/factory/zysp/detail/" + item.id);
      } else {
        this.$router.push("/factory/hzzp/detail/" + item.id);
      }
    },
    refreshItems() {
      console.log("refreshItems");
      getOrderById(this.$route.params.id)
        .then((rst) => {
          if (rst) {
            this.item = rst;
          }
          let data = [];
          let arr = this.item.entries.map((item) => {
            let list = [];
            if (this.apsInfo && this.apsInfo.length > 0) {
              list = this.apsInfo
                .filter((aps) => aps.itemName === item.itemName)
                .map((item) => {
                  return item;
                });

              console.error("list", list);
              list.forEach((item) => {
                item.lists.forEach((record) => {
                  data.push({
                    startDate: record.startDate,
                    endDate: record.endDate,
                    planNo: record.planNo,
                    plannedQuantity: record.plannedQuantity,
                    due: item.due,
                    plannedShort: item.plannedShort,
                  });
                });
              });
            }

            return { ...item, mesInfo: { list: data } };
          });
          this.$set(this.item, "entries", arr);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    invite() {
      this.$message.success("已邀请工厂展示排产计划");
    },
    paySubmit(orderId) {
      this.$router.push({
        path: "/sale/pur/pay",
        query: {
          o: this.item.code,
          c: this.item.count,
          f: this.item.carriage,
          p: this.item.amount,
        },
      });
    },
  },
};
</script>

<style src="../style.css"></style>
<style scoped lang="less">
.block {
  h3 {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #e6e6e6;
    padding: 0 71px;
    font-size: 16px;
    color: #4d4d4d;
    margin-bottom: 16px;
  }
}
.block-1 {
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 0 38px;
  align-items: center;
  > div {
    color: #4d4d4d;
    font-size: 16px;

    &:last-child {
      font-size: 14px;
    }
    span {
      margin-right: 8px;
    }
  }
}
.block-2 {
  .order-list {
    padding: 0 71px;
    .order-block {
      color: #808080;
      .order-top {
        display: flex;
        img {
          width: 220px;
          height: 220px;
          cursor: pointer;
        }
        .img-right {
          margin-left: 18px;
          > div {
            margin-top: 16px;
            &:first-child {
              margin-top: 0;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: bold;
            span.name {
              cursor: pointer;
            }
            span.series {
              display: inline-block;
              width: 132px;
              text-align: center;
              height: 21px;
              font-size: 14px;
              margin-left: 30px;
              background: linear-gradient(
                90deg,
                rgba(251, 199, 190, 1) 0%,
                rgba(254, 165, 166, 1) 100%
              );
              border-radius: 4px;
              color: #fff;
            }
          }
          .feature {
            display: flex;
            span {
              padding: 0 10px;
              border-right: 1px solid #707070;
              line-height: 1;

              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
          .price {
            b {
              color: #ee1818;
              font-weight: 400;
            }
          }
          .amount,
          .price,
          .logistic-status {
            span {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
.block-3 {
  > div {
    padding: 18px 71px;
    color: #808080;
    .info-wrap {
      display: flex;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
        > div {
          font-size: 12px;
          span,
          b {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      > div {
        width: 50%;
        span {
          color: #4d4d4d;
          margin-right: 24px;
        }
        b {
          color: #ee1818;
        }
      }
    }
  }
}
.trackWaybill {
  .box {
    .time {
      font-size: 14px;
      font-weight: 700;
      color: rgba(0,0,0,.65);
      position: absolute;
      left: -180px;
    }
    .site-status {
      font-size: 14px;
      font-weight: 700;
      color: rgba(0,0,0,.65);
    }
  }
}
</style>